import { NavLink } from "react-router-dom";
import phone from "../../assets/img/phone.png";
import homePhone from "../../assets/img/home_phone.png";
import location from "../../assets/img/location.png";
import email from "../../assets/img/email.png";

export default function Footer() {
  return (
    <footer className="footer">
      <section className="footer__upper">
        <div className="container container--inner">
          <div className="footer__grid">
            <div className="footer__col">
            <p style={{display:"flex",flexDirection:"column"}}>
              <NavLink
                className="footer__logo"
                to="/"
                data-aos="fade-up"
                data-aos-offset="220"
              >
                朝夕(香港)國際有限公司
              </NavLink>
              <NavLink
                className="footer__logo"
                to="/"
                data-aos="fade-up"
                data-aos-offset="220"
              >
                ZHAOXI (HK) INTERNATIONAL COMPANY LIMITED
              </NavLink>
              </p>
              {/* <p
                className="footer__slogan"
                data-aos="fade-up"
                data-aos-offset="220"
              >
                Permeate your
                <br />
                own Scent
              </p> */}
            </div>
            <div
              className="footer__col"
              data-aos="fade-up"
              data-aos-offset="220"
            >
              {/* <NavLink className="footer__text" to="/zhaoxi_hk">
              網站首頁
              </NavLink>
              <NavLink className="footer__text" to="/zhaoxi_hk">
              關於朝夕
              </NavLink>
              <NavLink className="footer__text" to="/zhaoxi_hk">
              產品中心
              </NavLink>
              <NavLink className="footer__text" to="/zhaoxi_hk">
              服務與支持
              </NavLink>
              <NavLink className="footer__text" to="/zhaoxi_hk">
              聯繫我們
              </NavLink> */}
            </div>
            <div
              className="footer__col"
              data-aos="fade-up"
              data-aos-offset="220"
            >
              <p className="footer__text">聯繫我們</p>
               <a href="/zhaoxi_hk" style={{display:"flex",alignItems:"center"}} className="footer__text">
               < img style={{width: "50px"}} src={phone} alt="" />
               <p  style={{display:"flex",flexDirection: "column"}}>
                <p>咨詢熱綫: 梁生</p>
                <p>00852－59938688</p>
                </p>
              </a>
              <a href="/zhaoxi_hk" style={{display:"flex",alignItems:"center"}} className="footer__text">
              < img style={{width: "50px"}} src={homePhone} alt="" />
              <p  style={{display:"flex",flexDirection: "column"}}>
              <p>辦公電話:</p>
              <p>00852－28561103</p>
              </p>
              </a>
              <a href="/zhaoxi_hk" style={{display:"flex",alignItems:"center"}} className="footer__text">
              < img style={{width: "50px"}} src={location} alt="" />
              <p  style={{display:"flex",flexDirection: "column"}}>
              <p>郵箱:</p>
              <p>120482515◎qq.com</p>
              </p>
              </a>
              <a href="/zhaoxi_hk" style={{display:"flex",alignItems:"center"}} className="footer__text">
              < img style={{width: "50px"}} src={email} alt="" />
              <p  style={{display:"flex",flexDirection: "column"}}>
              <p>地阯:</p><p>香港濱海街5號中興大廈16字樓9室</p>   </p>           </a>
            </div>
          </div>
        </div>
      </section>
      <section className="footer__lower">
        <div
          className="container container--inner"
          data-aos="fade-up"
          data-aos-offset="0"
        >
          <NavLink className="footer__link" to="/zhaoxi_hk">
            Privacy
          </NavLink>
          <NavLink className="footer__link" to="/zhaoxi_hk">
            Terms of Use
          </NavLink>
        </div>
      </section>
    </footer>
  );
}
