import React from "react";
import { ReactComponent as Cancel } from "../../assets/img/svg/cross.svg";

interface Props {
  onCancel?: any;
  className?: string;
}

const ShoppingCart: React.FunctionComponent<Props> = (props) => {
  const { onCancel } = props;
  return (
    <div className="drawer">
      <div className="drawer__head" data-aos="fade-left" data-aos-offset="0">
        <span>Cart</span>
        <Cancel width={18} onClick={onCancel} onKeyDown={onCancel} />
      </div>
      <div
        className={"drawer__content drawer__content--empty"}
        data-aos="fade-left"
        data-aos-offset="0"
      >
        Your Cart is Empty
      </div>
    </div>
  );
};

export default ShoppingCart;
