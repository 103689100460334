import React from "react";

interface Props {
  className?: string;
  skipTo?: string;
  children: React.ReactNode;
}

const SkipToMain: React.FunctionComponent<Props> = (props) => {
  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (props.skipTo) {
      const container: HTMLElement | null = document.querySelector(
        props.skipTo
      );

      if (container) {
        container.tabIndex = -1;
        container.focus();
        container.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => container.removeAttribute("tabindex"), 1000);
      }
    }
  };

  return (
    <button className={props.className} onClick={onClick}>
      {props.children}
    </button>
  );
};

SkipToMain.defaultProps = {
  className: "skip-nav",
  skipTo: "main:first-of-type",
};

export default SkipToMain;
