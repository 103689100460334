import { NavLink } from "react-router-dom";

export default function Navigation() {
  return (
    <ul className="header__menu">
      <li className="header__item">
        <NavLink className="header__link" end to="/">
        網站首頁
        </NavLink>
      </li>
      <li className="header__item">
        <NavLink className="header__link" to="/">
        關於朝夕
        </NavLink>
      </li>
      <li className="header__item">
        <NavLink className="header__link" to="/">
        產品中心
        </NavLink>
      </li>
      <li className="header__item">
        <NavLink className="header__link" to="/">
        服務與支持
        </NavLink>
      </li>
      <li className="header__item">
        <NavLink className="header__link" to="/">
        聯繫我們
        </NavLink>
      </li>
    </ul>
  );
}
