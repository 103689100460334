import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import Home from "../pages/home";
// import About from "../pages/about";
// import Contact from "../pages/contact";
// import Work from "../pages/work";
// import NotFound from "../pages/notfound";
import SkipToMain from "components/skipToMain";
import ScrollToTop from "components/scrollToTop";

function Routers() {
  return (
    <Router>
      <SkipToMain>Skip to Main Content</SkipToMain>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default Routers;
