// import { JellyBean } from "components/jellybean";
// import { ReactComponent as ScrollDownArrow } from "../../assets/img/svg/scrolldown.svg";
// import { ReactComponent as ArrowRight } from "../../assets/img/svg/arrow-right.svg";
// import heroImg from "../../assets/img/7_orange.png";
import heroImg from "../../assets/img/banner.jpg";
import cert1 from "../../assets/img/cert-1.png";
import cert2 from "../../assets/img/cert-2.png";
import cert3 from "../../assets/img/cert-3.png";
import cert4 from "../../assets/img/cert-4.png";
import prod1 from "../../assets/img/prod-1.png";
import prod2 from "../../assets/img/prod-2.png";
import prod3 from "../../assets/img/prod-3.png";
import prod4 from "../../assets/img/prod-4.png";
import aboutus from "../../assets/img/aboutus.jpg";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  offset: 360,
  duration: 800,
  anchorPlacement: "top-center",
});

export default function Home() {
  return (
    <main className="home-aos">
      <section className="hero">
        <picture className="hero__bg">
          <img className="hero__img" src={heroImg} alt="" />
        </picture>
        <div className="container" data-aos="fade-up">
          {/* <h1 className="hero__title">
            Scent is the KEY
            <br />
            to MEMORIES
          </h1>
          <p className="hero__desc">Scent, Certificate, Ingredients</p>
          <button className="hero__button">
            <span>Explore</span>
            <ArrowRight />
          </button> */}
          {/* <div className="scroll-down">
            <button
              className="scroll-down__btn"
              onClick={scrollDown}
              aria-label="Scroll down"
            >
              <span className="scroll-down__text">
                <p>Explore More</p>
              </span>
              <ScrollDownArrow />
            </button>
          </div> */}
        </div>
      </section>

      <section className="aboutus">
        <div className="container container--outer">
          <img
            className="aboutus__bg"
            src={aboutus}
            alt=""
            data-aos="fade-up"
          />
          <div className="aboutus__textbox" data-aos="fade-up">
            <h2>關於朝夕</h2>
            <p>
            朝夕（香港）國際有限公司ZHAOXI (HK) INTERNATIONAL COMPANY LIMITED是一家專註高端智能視覺檢測設備、外觀檢測設備、機器視覺檢測設備和光學檢測設備的銷售型公司。以工業4.0為目標，專註為客戶打造自動化解決方案，幫助客戶提高生産效率、降低製造成本、提升産品品質；以半導體産業為主，延伸至電子産品、燈具産品和新能源鋰電池産品檢測的公司。
            </p>
          </div>
        </div>
      </section>

      <section className="certificate">
        <div className="container container--outer">
          <h2 data-aos="fade-up">産品中心（一）</h2>
          <div className="certificate__grid" data-aos="fade-up">
            <div className="certificate__item">
              <div className="certificate__imgframe">
              <img className="certificate__img" src={cert1} alt="" />
              </div>
              {/* <span className="certificate__name">
              工業自動化智能視覺檢測設備
              </span> */}
              <span className="certificate__desc">工業自動化智能視覺檢測設備</span>
            </div>
            <div className="certificate__item">
              <div className="certificate__imgframe">
              <img className="certificate__img" src={cert2} alt="" />
              </div>
              {/* <span className="certificate__name">
                Level 2 ICAD x HK Perfume Trainee Certificate
              </span> */}
              <span className="certificate__desc">外觀視覺檢測設備</span>
            </div>
            <div className="certificate__item">
              <div className="certificate__imgframe">
              <img className="certificate__img" src={cert3} alt="" />
              </div>
              {/* <span className="certificate__name">
                Level 1 ICAD x HK Perfume Foundation Certificate
              </span> */}
              <span className="certificate__desc">環保測試儀，快速測試六大重金屬含量
</span>
            </div>
            <div className="certificate__item">
              <div className="certificate__imgframe">
              <img className="certificate__img" src={cert4} alt="" />
              </div>
              {/* <span className="certificate__name">
                ICAD x HK Perfumer Product Certificate
              </span> */}
              <span className="certificate__desc">應對RoHS2.0，快速篩查鄰苯二甲酸酯
</span>
            </div>
          </div>
        </div>
      </section>

      <section className="product">
        <div className="container container--outer">
          <h2 data-aos="fade-up">産品中心（二）</h2>
          <div className="product__grid">
            <div className="product__item" data-aos="fade-up">
              <div className="product__placeholder">
                <img className="product__img" src={prod1} alt="" />
              </div>
              <span className="product__name">鋰電池認證
</span>
            </div>
            <div className="product__item" data-aos="fade-up">
              <div className="product__placeholder">
                <img className="product__img" src={prod2} alt="" />
              </div>
              <span className="product__name">
              電池兼容
              </span>
            </div>
            <div className="product__item" data-aos="fade-up">
              <div className="product__placeholder">
                <img className="product__img" src={prod3} alt="" />
              </div>
              <span className="product__name">燈具産品認證
</span>
            </div>
            <div className="product__item" data-aos="fade-up">
              <div className="product__placeholder">
                <img className="product__img" src={prod4} alt="" />
              </div>
              <span className="product__name">其它産品認證
</span>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
