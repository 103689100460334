import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navigation from "../../layouts/navigation";
import { ReactComponent as Cart } from "../../assets/img/svg/cart.svg";
import { ReactComponent as Menu } from "../../assets/img/svg/menu.svg";

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

import MobileMenu from "./MobileMenu";
import ShoppingCart from "./ShoppingCart";

export default function Header() {
  // Scroll Animation
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // Shopping Cart Toggle
  const [drawerState, setDrawerState] = useState({
    left: false,
    right: false,
  });
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerState({ ...drawerState, right: open });
    };

  // Mobile Menu Toggle
  const toggleMenu =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerState({ ...drawerState, left: open });
    };

  return (
    <header className={`header` + (offset === 0 ? "" : " scrolled")}>
      <div className="container container--outer">
        <Menu
          className="header__icon header__icon--menu"
          onClick={toggleMenu(true)}
        />
        <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
        <NavLink className="header__logo" to="/">
          朝夕國際
        </NavLink>
        <NavLink className="header__logo" to="/">
         ZHAOXI (HK)
        </NavLink></div>
        <Navigation />
        <Cart
          className="header__icon header__icon--cart"
          onClick={toggleDrawer(true)}
        />

        <Drawer
          anchor="left"
          open={drawerState["left"]}
          onClose={toggleMenu(false)}
        >
          <Box
            sx={
              useMediaQuery("(min-width:575px)")
                ? { width: 400, overflow: "hidden" }
                : { width: "90vw", overflow: "hidden" }
            }
            role="presentation"
          >
            <MobileMenu onCancel={toggleMenu(false)} />
          </Box>
        </Drawer>

        <Drawer
          anchor="right"
          open={drawerState["right"]}
          onClose={toggleDrawer(false)}
        >
          <Box
            sx={
              useMediaQuery("(min-width:575px)")
                ? { width: 400, overflow: "hidden" }
                : { width: "90vw", overflow: "hidden" }
            }
            role="presentation"
          >
            <ShoppingCart onCancel={toggleDrawer(false)} />
          </Box>
        </Drawer>
      </div>
    </header>
  );
}
