import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import Divider from "@mui/material/Divider";
import { ReactComponent as Cancel } from "../../assets/img/svg/cross.svg";
import { ReactComponent as Instagram } from "../../assets/img/svg/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/img/svg/facebook.svg";
import { ReactComponent as Whatsapp } from "../../assets/img/svg/whatsapp.svg";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  duration: 800,
  anchorPlacement: "top-center",
});

interface Props {
  onCancel?: any;
  className?: string;
}

const MobileMenu: React.FunctionComponent<Props> = (props) => {
  const { onCancel } = props;
  return (
    <div className="drawer">
      <div className="drawer__head" data-aos="fade-right" data-aos-offset="0">
        <Cancel width={20} onClick={onCancel} onKeyDown={onCancel} />
      </div>
      <div
        className="drawer__content"
        data-aos="fade-right"
        data-aos-offset="0"
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton>網站首頁</ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem disablePadding>
            <ListItemButton>關於朝夕</ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem disablePadding>
            <ListItemButton>產品中心</ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem disablePadding>
            <ListItemButton>服務與支持</ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem disablePadding>
            <ListItemButton>聯繫我們</ListItemButton>
          </ListItem>
        </List>
        {/* <List>
          <ListItem disablePadding>
            <ListItemButton>Contact</ListItemButton>
          </ListItem>
        </List> */}
      </div>
      <div
        className="drawer__foot"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-offset="0"
      >
        <Instagram width={20} />
        <Facebook width={20} />
        <Whatsapp width={20} />
      </div>
    </div>
  );
};

export default MobileMenu;
